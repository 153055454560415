import React from 'react';

export default function TermsConditions(props) {
    return (
        <div className="flex items-center justify-center min-h-screen">
            <div className="w-fit p-5 md:p-20 mx-2">
                <div>
                    <h1 className="text-4xl text-gray-900 font-bold mb-10">TERMS OF USE</h1>
                    <div>
                        <p className="text-gray-800 font-bold text-lg my-10">
                            Acceptance of the Terms of Use
                        </p>
                        <p className="text-gray-700 my-10">These terms of use are entered into by and between You and Ovation Tax Group  (“Company”, “We”, or “Us”). The following terms and conditions, together with any documents they expressly incorporate by reference (collectively, these “Terms of Use”), govern Your access to and use of the Ovation Tax Group Website and the related websites protection, Ovation Tax Group plans, and Ovationtaxgroup.com including any content, functionality, and services offered on or through such websites (each hereinafter referred to as the “Website”), whether as a guest or registered user.</p>


                        <p className="text-gray-700 my-10">Please read the Terms of Use carefully before You start to use the Website. <b>By using the Website or by clicking to accept or agree to the Terms of Use when this option is made available to  You, You accept and agree to be bound and abide by these Terms of Use and Our Privacy Policies found at https://OvationTaxGroup.com/privacy-policy/ incorporated herein by reference.</b></p>
                        <p className="text-gray-700 my-10">If You do not want to agree to these Terms of Use or the Privacy Policies, You must not access or use the Website.</p>
                        <p className="text-gray-700 my-10">This Website is offered and available to users who are 18 years of age or older and reside in the United States or any of its territories or possessions. By using this Website,  You represent and warrant that You are of legal age and eligibility requirements to form a binding contract with the Company for the jurisdiction in which you reside. If You are not of legal age or do not meet eligibility requirements to form a binding contract with the Company, You must not access or use the Website.</p>

                        <p className="text-gray-800 font-bold text-lg my-2">Changes to the Terms of Use</p>
                        <p className="text-gray-700 my-10">We may revise and update these <b>Terms of Use</b> from time to time in our sole discretion. All changes are effective immediately upon Us posting them and apply to all access to and use of the Website thereafter, except any changes to the dispute resolution provisions set out in Governing Law and Jurisdiction will not apply to any disputes for which the parties have actual notice on or before the date the change is posted on the Website.</p>
                        <p className="text-gray-700 my-10">Your continued use of the Website following the posting of revised <b>Terms of Use</b> means that You accept and agree to the changes. You are expected to check this page periodically so that You are aware of any changes.</p>

                        <p className="text-gray-800 font-bold text-lg my-2">Accessing the Website and Account Security</p>
                        <p className="text-gray-700 my-10">We reserve the right to withdraw or amend this Website, and any service or material We provide on the Website, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Website is unavailable at any time or for any period. From time to time, We may restrict access to some parts of the Website, or the entire Website, to users, including registered users.</p>
                        <p className="text-gray-700 my-10">You are responsible for both (i) making all arrangements necessary for You to have access to the Website, and (ii) ensuring that all persons who access the Website through Your internet connection are aware of these Terms of Use and comply with them.</p>
                        <p className="text-gray-700 my-10">The Website is provided on an "AS IS" and "AS AVAILABLE" basis. We disclaim all warranties, express or implied. We will not be liable for damages or losses arising from the Website's use. You agree to indemnify and hold harmless Us and our affiliates from any claims or losses.</p>
                        <p className="text-gray-700 my-10">To access the Website or some of the resources it offers,  You may be asked to provide certain registration details or other information. It is a condition of Your use of the Website that all the information  You provide on the Website is correct, current, and complete.  You agree that all information  You provide, and  Your use of the Website, is governed by Our <a href="https://optimataxrelief.com/about-us/privacy/">Privacy Policies</a>, and  You consent to all actions We take with respect to  Your information consistent with Our Privacy Policies.</p>
                        <p className="text-gray-700 my-10">You are responsible for maintaining the confidentiality of your account information. Any username, password, or any other piece of information You utilize as part of Our security procedures to access the Website is confidential, and You must not disclose it to any other person or entity. You also acknowledge that Your account is personal to  You and agree not to provide any other person with access to this Website or portions of it using Your user name, password, or any other security information. You agree to notify Us immediately of any unauthorized access to, or use of, Your user name or password or any other breach of security. You also agree to ensure that You exit from  Your account at the end of each session. You should use particular caution when accessing Your account from a public or shared computer so that others are not able to view or record Your password or other personal information.</p>
                        <p className="text-gray-700 my-10">We have the right to disable any user name, password, or other identifier at any time, in Our sole discretion for any or no reason including if, in Our opinion, You have violated any provision of these <b>Terms of Use</b>.</p>

                        <p className="text-gray-800 font-bold text-lg my-2">Intellectual Property Rights</p>
                        <p className="text-gray-700 my-10">The Website and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by the Company, its licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights law.</p>
                        <p className="text-gray-700 my-10">These Terms of Use permit You to use the Website for Your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Website, except as follows: (i)  Your computer may temporarily store copies of such materials in RAM incidental to Your accessing and viewing those materials; (ii) You may store files that are automatically cached by Your Web browser for display enhancement purposes; (iii)  You may print or download one copy of a reasonable number of pages of the Website for Your own personal, non-commercial use and not for further reproduction, publication, or distribution; (iv) You may print or download copies of documents provided to You as part of the services we provide to You through Our Website or web-based portal for Your records and Your own personal, non-commercial use; and (v) if We provide desktop, mobile, or other applications for download, You may download a single copy to Your computer, tablet, or mobile device for Your own personal, non-commercial use, provided You agree to be bound by Our end user license agreement for such applications. You must not access or use any part of the Website or any services or materials available through the Website for any commercial purposes, including reselling and/or co-branding/private labeling.</p>
                        <p className="text-gray-700 my-10">If You print, copy, modify download, or otherwise use or provide any other person with access to any part of the Website in breach of the Terms of Use, Your right to use the Website will stop immediately and You must, at Our option, return or destroy any copies of the materials You have made. No right, title, or interest in or to the Website or any content on the Website is transferred to You, and all rights not expressly granted are reserved by the Company. Any use of the Website not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark, and other laws.</p>

                        <p className="text-gray-800 font-bold text-lg my-2"> Trademarks</p>
                        <p className="text-gray-700 my-10">The Company name and logo, and all related names, logos, product and service names, designs, and slogans are trademarks of the Company or its affiliates or licensors. You must not use such marks without the prior written permission of the Company. All other names, logos, products and service names, designs, and slogans on this Website are the trademarks of their respective owners.</p>


                        <p className="text-gray-800 font-bold text-lg my-2">Prohibited Uses</p>
                        <p className="text-gray-700 my-10">You may use the Website only for lawful purposes and in accordance with these Terms of Use. You agree not to use the Website: (i) in any way that violates any applicable federal, state, local, or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries); (ii) for the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information, or otherwise; (iii) to send, knowingly receive, upload, download, use, or re-use any material that does not comply with the <b>Content Standards</b> set out in these Terms of Use; (iv) to transmit, or procure the sending of, any advertising or promotional material without prior written consent, including any “junk mail”, “chain letter”, “spam”, or any other similar solicitation; (v) to impersonate or attempt to impersonate the Company, a Company employee, or another user, or any other person or entity (including, without limitation, by using email addresses or user names associated with any of the foregoing); or (vi) to engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Website, or which as determined by Us, may harm the Company or users of the Website or expose them to liability.</p>
                        <p className="text-gray-700 my-10">Additionally, You agree not to: (i) use the Website in any manner that could disable, overburden, damage, or impair the site or interfere with any other party’s use of the Website, including their ability to engage in real time activates through the Website; (ii) use any robot, spider, or other automatic device, process, or means to access the Website for any purpose, including monitoring or copying any of the material on the Website; (iii) use any manual process to monitor or copy any of the material on the Website or for any other unauthorized purpose without Our prior written consent; (iv) use any device, software, or routine that interferes with the proper working of the Website; (v) introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful; (vi) attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Website, the server on which the Website is stored, or any server, computer, or database connected to the Website; (vii) attack the Website via a denial-of-service attack or a distributed denial-of-service attack; or (viii) otherwise attempt to interfere with the proper working of the Website.</p>



                        <p className="text-gray-800 font-bold text-lg my-2">Content Standards</p>
                        <p className="text-gray-700 my-10">These content standards apply to any and all documents and materials (collectively, “Materials”) sent, knowingly received, uploaded, downloaded, used, or re-used from or to the Website. All Materials must in their entirety comply with all applicable federal, state, local, and international laws and regulations. Without limiting the foregoing, Materials must not (i) contain any material that is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory, or otherwise objectionable; (ii) promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation, or age; (iii) Infringe any patent, trademark, trade secret, copyright, or other intellectual property or other rights of any other person; (iv) violate the legal rights (including the rights of publicity and privacy) of others or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms of Use and our Privacy Policies; (v) be likely to deceive any person; (vi) promote any illegal activity, or advocate, promote, or assist any unlawful act; (vii) cause annoyance, inconvenience, or needless anxiety or be likely to upset, embarrass, alarm, or annoy any other person; (viii) impersonate any person, or misrepresent  Your identity or affiliation with any person or organization; (ix) involve commercial activities or sales, such as contests, sweepstakes, and other sales promotions, barter, or advertising; (x) give the impression that they emanate from or are endorsed by us or any other person or entity, if this is not the case.                        </p>


                        <p className="text-gray-800 font-bold text-lg my-2">Reliance on Information Posted</p>
                        <p className="text-gray-700 my-10">The information presented on or through the Website is made available solely for general information purposes. We do not warrant the accuracy, completeness, or usefulness of this information. Any reliance You place on such information is strictly at  Your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by You or any other visitor to the Website, or by anyone who may be informed of any of its contents.</p>
                        <p className="text-gray-700 my-10">This Website may include content provided by third parties, including materials provided by other third-party licensors, syndicators, aggregators, and/or reporting services. All statements and/or opinions expressed in these materials, and all articles and responses to questions and other content, other than the content provided by the Company, are solely the opinions and the responsibility of the person or entity providing those materials. These materials do not necessarily reflect the opinion of the Company. We are not responsible, or liable to You or any third party, for the content or accuracy of any materials provided by any third parties.</p>

                        <p className="text-gray-800 font-bold text-lg my-2">Changes to the Website</p>
                        <p className="text-gray-700 my-10">We may update the content on this Website from time to time, but its content is not necessarily complete or up-to-date. Any of the material on the Website may be out of date at any given time, and We are under no obligation to update such material.</p>

                        <p className="text-gray-800 font-bold text-lg my-2">Information About  You and  Your Visits to the Website</p>
                        <p className="text-gray-700 my-10">All information We collect on this Website is subject to Our Privacy Policies. By using the Website, You consent to all actions taken by Us with respect to Your information in compliance with the Privacy Policies.</p>

                        <p className="text-gray-800 font-bold text-lg my-2">Consent to be Contacted via ATDS, Pre-Recorded Message, and Text Message</p>
                        <p className="text-gray-700 my-10">You acknowledge and understand that by using the Website and providing a phone number, including a cellular phone number, via the Website at any point in time, You provide express written consent to be contacted by Company and its affiliates and tax services partners using automated dialing equipment, prerecorded or artificial voice messages (including interactive voice recognition), and SMS/MMS text messages to discuss the products and services offered by Company, its affiliates, and/or tax services partners, including telemarketing sales calls and information calls in response to  Your requests, to complete transactions, and to facilitate any service offering. You acknowledge and agree that You are authorized to receive calls at any number provided and to consent to receive calls from Company and its affiliates and tax services partners. Receipt of residential and cell phone calls (including text messages) may be subject to charges from Your service provider. You also agree to receive emails from Company to any email address You provide, including e-mails to Your mobile device.</p>
                        <p className="text-gray-700 my-10">The scope of this consent includes any contact related to or arising out of Your use of the Website, Your creation of an account, Your submission of a form or similar information including a phone number to Company via the Website, and subsequent contact regarding the products or services Company or its affiliates and/or tax services partners offer.</p>
                        <p className="text-gray-700 my-10">You agree that We may monitor and record telephone conversations between You and Company. You also agree that Company may leave a message at any number You provide to Company as Your home or cell phone number.</p>

                        <p className="text-gray-800 font-bold text-lg my-2">Linking to the Website</p>
                        <p className="text-gray-700 my-10">You may link to Our homepage, provided You do so in a way that is fair and legal and does not damage Our reputation or take advantage of it, but You must not establish a link in such a way as to suggest any form of association, approval, or endorsement on Our part without Our express written consent. You agree to cooperate with Us in causing any unauthorized framing or linking immediately to stop. We reserve the right to withdraw linking permission without notice at any time at Our discretion.</p>


                        <p className="text-gray-800 font-bold text-lg my-2">Links from the Website</p>
                        <p className="text-gray-700 my-10">If the Website contains links to other sites and resources provided by third parties, these links are provided for Your convenience only. This includes links contained in advertisements, including banner advertisements and sponsored links. We have no control over the contents of those sites or resources and accept no responsibility for them or for any loss or damage that may arise from Your use of them. If You decide to access any of the third-party websites linked to this Website, You do so entirely at Your own risk and subject to the terms and conditions of use for such websites.</p>


                        <p className="text-gray-800 font-bold text-lg my-2">Geographic Restrictions</p>
                        <p className="text-gray-700 my-10">The owner of the Website is based in the United States. We provide this Website for use only by persons located in the United States. We make no claims that the Website or any of its content is accessible or appropriate outside of the United States.</p>


                        <p className="text-gray-800 font-bold text-lg my-2">Disclaimer of Warranties</p>
                        <p className="text-gray-700 my-10">You understand that We cannot and do not guarantee or warrant that files available for downloading from the internet or the Website will be free of viruses or other destructive code.  You are responsible for implementing sufficient procedures and checkpoints to satisfy Your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data. to the fullest extent provided by law, we will not be liable for any loss or damage caused by a distributed denial-of-service attack, viruses, or other technologically harmful material that may infect  your computer equipment or other device computer or other device programs, data, or other proprietary material due to  your use of the website or any services or items obtained through the website or to  your downloading of any material posted on it, or on any website linked to it.</p>
                        <p className="text-gray-700 my-10">Your use of the Website, its content, and any services or items obtained through the website is at your own risk. the website, its content, and any services or items obtained through the website are provided on an “as is” and “as available” basis, without any warranties of any kind, either express or implied. Neither the company nor any person associated with the company makes any warranty or representation with respect to the completeness, security, reliability, quality, accuracy, or availability of the website. without limiting the foregoing, neither the company nor anyone associated with the company represents or warrants that the website, its content, or any services or items obtained through the website will be accurate, reliable, error-free, or uninterrupted, that defects will be corrected, that our site or the server that makes it available are free of viruses or other harmful components, or that the website or any services or items obtained through the website will otherwise meet  your needs or expectations.</p>
                        <p className="text-gray-700 my-10">To the fullest extent provided by law, the company hereby disclaims all warranties of any kind, whether express or implied, statutory, or otherwise, including but not limited to any warranties of merchantability, non-infringement, and fitness for particular purpose.</p>
                        <p className="text-gray-700 my-10">The foregoing does not affect any warranties that cannot be excluded or limited under applicable law.</p>


                        <p className="text-gray-800 font-bold text-lg my-2">Limitation on Liability</p>
                        <p className="text-gray-700 my-10">To the fullest extent provided by law, in no event will the company, its affiliates, or their licensors, service providers, employees, agents, officers, members, or directors be liable for damages of any kind, under any legal theory, arising out of or in connection with  your use, or inability to use, the website, any websites linked to it, any content on the website or such other websites, any loss or damage caused by a distributed denial-of-service attack, viruses, or other technologically harmful material that may infect  your computer or device equipment, computer and other device programs, data, or other proprietary material due to your use of the website or any services or items obtained through the website or to  your downloading of any material posed on it, or on any website linked to it, including but not limited to any direct, indirect, special, incidental, consequential, or punitive damages, including but not limited to, personal injury, pain and suffering, emotional distress, loss of revenue, loss of profits, loss of business or anticipated savings, loss of use, loss of goodwill, loss of data, and whether caused by tort (including negligence), breach of contract, or otherwise, even if foreseeable. The foregoing does not affect any liability that cannot be excluded or limited under applicable law.</p>

                        <p className="text-gray-800 font-bold text-lg my-2">Indemnification</p>
                        <p className="text-gray-700 my-10">You agree to defend, indemnify, and hold harmless the Company, its affiliates, licensors, and service providers, and its and their respective officers, directors, members, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys’ fees) arising out of or relating to Your violation of these Terms of Use or Your use of the Website, including, but not limited to, any Materials You provide, any use of the Website’s content, services, and products other than as expressly authorized in these Terms of Use or Your use of any information obtained from the Website.</p>

                        <p className="text-gray-800 font-bold text-lg my-2">Governing Law</p>
                        <p className="text-gray-700 my-10">All matters relating to the Website and these Terms of Use and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of California without giving effect to any choice or conflict of law provision or rule.</p>

                        <p className="text-gray-800 font-bold text-lg my-2">Arbitration of Dispute</p>
                        <p className="text-gray-700 my-10">(a) To the extent allowed by law,  you and company each agree that each may only bring claims against the other in his/her or its individual capacity and not as a plaintiff on a class wide basis, further, company and  you waive any right to trial by a jury in any lawsuit, or other similar proceeding.</p>
                        <p className="text-gray-700 my-10">(b) In the event of any controversy, claim or dispute between  you and company arising out of or relating to the website, these terms of use, or the breach, termination, enforcement, interpretation or validity thereof, including the determination of the scope or applicability of this agreement to arbitrate, and any controversy, claim or dispute arising prior to this agreement or after its termination arising out of or in anyway related to phone calls or other attempted contact, shall be solely determined by binding arbitration in orange county, california, or in the county in which  you reside, in accordance with the laws of the state of california for agreements to be made and to be performed in california, or under the laws of the state in which  you reside for any other agreements.</p>
                        <p className="text-gray-700 my-10">(c) Any claimant under this agreement must first submit to the other party a written notice of the claimant’s claim against or dispute with the other party. such notice must include an explanation of the claim and a statement of the claimant’s request for resolution. The party receiving the claim or dispute shall then be entitled to a period of sixty (60) days to cure such claim or dispute. If the parties are unable to resolve the claim or dispute within such a 60-day period, then the claim or dispute shall be solely resolved, on an individual, non-class basis, by binding arbitration before a single arbitrator. Prior to the claimant commencing arbitration, the parties shall mutually agree upon the arbitral institution that will administer the arbitration. In the event that no arbitral institution can be agreed upon, then the parties agree to use jams. the arbitration shall be administered pursuant to the mutually agreed arbitral institutions rule and procedures and a single arbitrator will be selected therefrom.</p>
                        <p className="text-gray-700 my-10">(d) The arbitrator shall be neutral and independent, licensed to practice law in the jurisdiction where the arbitration is taking place and shall comply with the arbitral institution’s code of ethics and have experience in the subject matter of the dispute. the arbitrator shall have the authority to grant any remedy or relief that the arbitrator deems just and equitable and within the scope of the agreement of the parties provided it is individual in nature and allowable within relevant state law. The arbitrator shall issue a reasoned award following the applicable law and such award shall be final and shall not be subject to vacation or modification. The award and the record in arbitration shall be confidential, except that judgment on the award made by the arbitrator may be entered in any court having jurisdiction over the parties. If either party fails to comply with the arbitrator’s award, the prevailing party may petition the court for enforcement. the parties agree that the arbitrator may not consolidate proceedings of more than one person’s claims, and may not otherwise preside over any form of representative or class proceedings. To the extent permitted by law, the parties shall bear the cost of arbitration including attorney's fees, separately. If the arbitrator determines that a party has generally prevailed in the arbitration preceding, then the arbitrator shall award to that party its reasonable attorney’s fees and legal costs. In the event a party fails to proceed with arbitration or quit, including reasonable attorney’s fee for having to compel arbitration or defend or enforce the award. This section and the requirement to arbitrate shall survive any termination of these terms of use or use of the website.</p>
                        <p className="text-gray-700 my-10">(e) Should any provision of this arbitration agreement – accepting the requirement that arbitration proceed on an individual basis only – be deemed unenforceable, it shall be deemed severable and the remainder of the arbitration agreement shall be enforced.</p>

                        <p className="text-gray-800 font-bold text-lg my-2">Limitation on Time to File Claims</p>
                        <p className="text-gray-700 my-10">Any cause of action or claim you may have arising out of or relating to these terms of use or the website must be commenced within one (1) year after the cause of action accrues, otherwise, such cause of action or claim is permanently barred.</p>

                        <p className="text-gray-800 font-bold text-lg my-2">Waiver and Severability</p>
                        <p className="text-gray-700 my-10">No waiver by the Company of any term or condition set out in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term of condition, and any failure of the Company to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.                        </p>
                        <p className="text-gray-700 my-10">If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect.</p>

                        <p className="text-gray-800 font-bold text-lg my-2">Entire Agreement</p>
                        <p className="text-gray-700 my-10">The Terms of Use and Our Privacy Policies constitute the sole and entire agreement between You and Company regarding the Website and supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding the Website.</p>
                        <p className="text-gray-700 my-10"><b>Last Update: 10/10/24</b></p>
                    </div>
                </div>
            </div>
        </div>
    );
}
