import './App.css';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import LandingPage from './components/LandingPage';
import Layout from './components/Layout';
import PrivacyPolicy from './components/PrivacyPolicy';
import Success from './components/Success';
import TermsConditions from './components/TermsConditions';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout did={`800-668-1781`} />}>
            <Route path='/' element={<LandingPage did={`800-668-1781`} />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy did={`800-668-1781`} />} />
            <Route path='/terms-of-use' element={<TermsConditions did={`800-668-1781`} />} />
            <Route path='/confirmation/success' element={<Success did={`800-668-1781`} />} />
          </Route>
        </Routes>
      </BrowserRouter>
      {/* Footer start */}
      <div className='py-10 text-sm bg-blue-800 text-white text-center'>
        <img src="/logo.png" alt="Ovation Tax Group" className='h-10 mb-4 mx-auto' />
        <p>©2023 Ovation Tax Group. All rights reserved. </p>
        <div className='flex items-center justify-center'>
          <a id="privacyPolicyButton" className="mx-1 underline" href="/privacy-policy">Privacy Policy</a>
          <a id="termsOfUseButton" className="mx-1 underline" href="/terms-of-use">Terms of Use</a>
        </div>
      </div>
      {/* Footer end */}
    </>
  );
}
export default App;