import React from 'react';

export default function PrivacyPolicy(props) {
    return (
        <div className="flex items-center justify-center min-h-screen">
            <div className="w-fit p-5 md:p-20 mx-2">
                <h2 className="text-4xl text-gray-900 font-bold mb-10">PRIVACY POLICY</h2>
                <div>
                    <p className="text-gray-700 my-10">At Ovation Tax Group (also referred to as “Ovation,” “us” or “we”), your privacy is important.  This Privacy Policy (the “Policy”) discloses the privacy practices of our websites (the “Sites”), SMS services ("SMS Services"), as well as related products and services we may offer to you (collectively referred to as the “Services”).  This Privacy Policy explains how we collect, use, disclose, and protect your personal information. By using our Sites and Services, you agree to the terms of this Policy as they may be amended from time to time.</p>
                    <p className="text-gray-700 my-10">What Categories of Personal Information Do We Collect, and How Do We Obtain the Personal Information?</p>
                    <p className="text-gray-700 my-10">We treat data that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a consumer or household as “Personal Information.” Data that has been de-identified or aggregated so that it cannot reasonably be linked to you is not Personal Information, and we may use de-identified data for any purpose.</p>
                    <p className="text-gray-700 my-10">We may collect the following categories of Personal Information about you:</p>
                    <ul className="ml-10 list-disc text-gray-700 my-10">
                        <li>
                            <p className="text-gray-700 my-10">Identifying Data, such as name, postal address, phone, and email address. We obtain most of the Personal Information that we collect about you from you when you:  (a) interact with our Sites and SMS Services, including when you contact and communicate with us via forms contained on our Sites, (b) when you seek information about, or purchase, our Services, or (c) contact and communicate with us, including our sales or support teams.  We also may receive this information from our service providers and marketing partners, including through the use of third-party databases from which data is extracted and combined with data otherwise obtained by us.                                </p>
                        </li>
                        <li>
                            <p className="text-gray-700 my-10">Internet or Similar Network Activity, such as your IP address and browsing activity on our Sites, as well as your interaction with our advertising and other communications. You provide this information to us or automatically through your interaction with our products or services.  We also may receive this information from our service providers or marketing partners.  For more information about cookies, please see below.</p>
                        </li>
                        <li>
                            <p className="text-gray-700 my-10">Commercial Data, such as information relating to your purchases and services, and purchasing or consuming histories, tendencies or interests. You provide this information to us or automatically through your interaction with our products or services.  We also may receive this information from our service providers or marketing partners.
                            </p>
                        </li>
                        <li>
                            <p className="text-gray-700 my-10">Financial Data, such as financial account numbers to pay for our Services. In addition, you may provide financial information necessary for us to provide our Services.
                            </p>
                        </li>
                        <li>
                            <p className="text-gray-700 my-10">Geolocation Data, such as information about approximate location, such as your IP address, collected automatically when you access our Sites.
                            </p>
                        </li>
                        <li>
                            <p className="text-gray-700 my-10">Professional or employment-related information, which you provide for us to provide our Services.
                            </p>
                        </li>
                        <li>
                            <p className="text-gray-700 my-10">Demographic information, such as age, citizenship, gender and marital status.
                            </p>
                        </li>
                        <li>
                            <p className="text-gray-700 my-10">Sensitive Information, such as government identification numbers and health data. You provide this information to us so that we can facilitate our Services on your behalf.
                            </p>
                        </li>
                        <li>
                            <p className="text-gray-700 my-10">SMS-Specific Information, such as phone number, carrier information, and SMS message content and responses, that may be generated when interacting with our SMS Services.
                            </p>
                        </li>
                    </ul>


                    <p className="text-gray-700 my-10 underline">What Are Our Business or Commercial Purposes for Using Your Personal Information, and With Whom Do We Share Your Personal Information?</p>

                    <p className="text-gray-700 my-10">We may use or share your Personal Information as follows:</p>

                    <ul className="ml-10 list-disc text-gray-700 my-10">
                        <li>
                            <p className="text-gray-700 my-10">Identifying Data: We may use your identifiers: (1) to provide our Sites and Services; (2) to administer your account and process your payments for Services; (3) to operate our Sites; (4) to send marketing materials about products, special offers or other information which we think you may find interesting; and (5) to comply with legal, regulatory or contractual requirements.  We may share this information with service providers, advertising networks, and data analytics providers.  We also share this information with government agencies to facilitate our Services on your behalf.</p>
                        </li>
                        <li>
                            <p className="text-gray-700 my-10">Internet or Similar Network Activity: We may use this information to customize the Site according to your interests, to help us improve our Services, and to process your payments for Services.  See below for additional information about advertising and analytics services provided by others.  We also may use this information to detect security incidents and protect against malicious, deceptive, fraudulent or illegal activity.  We may share this information with service providers for fraud protection.  We also may share this information with advertising networks and data analytics providers.</p>
                        </li>
                        <li>
                            <p className="text-gray-700 my-10">Commercial Data: We may use this information to customize the Sites according to your interests, to help us improve our Services, and to process your payments for Services.  We may share this information with advertising networks and data analytics providers.</p>
                        </li>
                        <li>
                            <p className="text-gray-700 my-10">Financial Data: We use this data to process and fulfill your purchases.  We may share this information with a service provider who processes your payment.   We also may use this information to detect security incidents and protect against malicious, deceptive, fraudulent or illegal activity.  We may share this information with service providers for fraud protection.  We also may use your financial information to provide you with Services that you request, and may share this information with government agencies to provide those Services.</p>
                        </li>
                        <li>
                            <p className="text-gray-700 my-10">Geolocation Data: We may use this information to customize the Sites according to your interests, to help us improve our Services, and to process your payments for Services.  We also may use this information to detect security incidents and protect against malicious, deceptive, fraudulent or illegal activity.  We may share this information with service providers for fraud protection.  We also may share this information with advertising networks and data analytics providers.</p>
                        </li>
                        <li>
                            <p className="text-gray-700 my-10">Professional or Employment-Related Information: We may share this data with government agencies to facilitate our Services on your behalf.
                            </p>
                        </li>
                        <li>
                            <p className="text-gray-700 my-10">Demographic information: We may use this information to provide you with Services that you request, and may share with government agencies to provide those Services.</p>
                        </li>
                        <li>
                            <p className="text-gray-700 my-10">Sensitive Information: We use this information to provide you with Services that you request, and may share this information with government agencies to provide those Services.</p>
                        </li>
                        <li>
                            <p className="text-gray-700 my-10">SMS-Specific Data:  We may use Opt-In and phone number to send and receive SMS messages, including promotional offers, updates, and user specific transactional messages.</p>
                        </li>
                    </ul>

                    <p className="text-gray-700 my-10">In addition, where consistent with applicable laws, we may use or disclose the above categories of Personal Information:</p>

                    <ul className="ml-10 list-disc text-gray-700 my-10">
                        <li>
                            <p className="text-gray-700 my-10">with your consent;
                            </p>
                        </li>
                        <li>
                            <p className="text-gray-700 my-10">with subsidiaries or legal affiliates;
                            </p>
                        </li>
                        <li>
                            <p className="text-gray-700 my-10">to prepare, negotiate or perform a contract with you;
                            </p>
                        </li>
                        <li>
                            <p className="text-gray-700 my-10">to respond to subpoenas, court orders, or other legal process or requests as required or permitted by law from competent governmental or judicial authorities;
                            </p>
                        </li>
                        <li>
                            <p className="text-gray-700 my-10">to conduct internal research to develop, improve or repair our Sites, Services or technology;
                            </p>
                        </li>
                        <li>
                            <p className="text-gray-700 my-10">to protect our rights, protect your safety or the safety of others, or establish or preserve a legal claim or defense;
                            </p>
                        </li>
                        <li>
                            <p className="text-gray-700 my-10">to investigate, prepare for, establish or defend legal claims;
                            </p>
                        </li>
                        <li>
                            <p className="text-gray-700 my-10">comply with applicable law;
                            </p>
                        </li>
                        <li>
                            <p className="text-gray-700 my-10">in conjunction with a corporate restructuring, sale of assets, merger or divestiture; or
                            </p>
                        </li>
                        <li>
                            <p className="text-gray-700 my-10">to prevent fraud or other illegal activities, such as willful attacks on our information technology systems.
                            </p>
                        </li>
                    </ul>

                    <p className="text-gray-700 my-10">We take reasonable steps to ensure that service providers to whom we share your Personal Information are contractually obligated to keep the data that we provide to them confidential and to use such information only as we permit.</p>
                    <p className="text-gray-700 my-10">We do not share any SMS opt-in consent with third-party allies.</p>
                    <p className="text-gray-700 my-10 italic">Data Retention</p>
                    <p className="text-gray-700 my-10">We store the information we collect about you for as long as is necessary for the business or commercial purpose(s) for which we collected it or for other legitimate business purposes, including to meet our legal, regulatory, or other compliance obligations.</p>
                    <p className="text-gray-700 my-10 italic">Use and Disclosure of Sensitive Information</p>
                    <p className="text-gray-700 my-10"> We do not use or disclose sensitive personal information as defined by the California Consumer Privacy Act for any purposes that would require a consumer to exercise a right to limit according to California law.</p>
                    <p className="text-gray-700 my-10 italic">Use of Cookies and Interest-Based Advertising</p>
                    <p className="text-gray-700 my-10">Like many commercial websites, we analyze how visitors use our Site with “cookie” technology. A cookie is a small text file that is placed on your computer when you access the Sites and allows us to recognize you each time you visit the Sites. We may use cookies to (1) enhance or personalize your usage and experience on the Sites; (2) monitor usage of the Sites; and (3) improve the Sites and our Services, including by providing you with interest-based ads.  We may work with service providers or third parties that help us track, collect and analyze this information.  We may combine the information we collect through cookies with other information we have collected from you or information from other sources.  We partner with third-party advertising companies such as [] that use tracking technologies to serve our advertisements across the Internet. These advertising companies may collect information about your visits to ours and other websites.  These advertising companies serve ads on behalf of us and others on nonaffiliated sites, and some of those ads may be personalized, meaning that they are intended to be relevant to you based on information collected about your visits to our Sites and elsewhere over time.</p>
                    <p className="text-gray-700 my-10">Most browsers are initially set to accept cookies. If you choose, you can set your browser to reject cookies or you can manually delete individual cookies or all of the cookies on your computer by following your browser’s help file directions.  Please refer to your browser instructions to learn more about how to adjust or modify your browser settings.  However, if your browser is set to reject cookies or you manually delete cookies, you may have some trouble accessing and using some of the pages and features on our Sites. Please note that browser-management tools for cookies are outside of our control, and we cannot guarantee their effectiveness.  For additional information on rights to opt-out of targeted advertising, please visit the “Your Rights and Choices” section below.</p>
                    <p className="text-gray-700 my-10 underline">Security of Your Personal Information</p>
                    <p className="text-gray-700 my-10">We use commercial reasonably physical, technical and administrative security measures to reduce the risk of loss, misuse, unauthorized access, and disclosure.  Please note, however, that no security measures are perfectly secure, and we are unable to guarantee the security of your Personal Information.</p>
                    <p className="text-gray-700 my-10 underline">Third Party Websites and Applications</p>
                    <p className="text-gray-700 my-10">Our Sites may contain links to websites or applications hosted by third parties.  Any data collected through third party websites or applications shall be subject to the privacy practices of the third party, and you should review the privacy policies of these third parties prior to sharing any data.</p>
                    <p className="text-gray-800 font-bold text-lg my-2">Your Rights and Choices</p>
                    <p className="text-gray-700 my-10 italic">Marketing Communications</p>
                    <p className="text-gray-700 my-10">We give you certain choices regarding our use and disclosure of your Personal Information for promotional purposes.  You may opt-out from receiving electronic communications from us by contacting us as detailed in the “Contact Us” section below.  If you no longer want to receive promotional-related emails from us, you may opt-out according to instructions in such communications.  Please note that if you opt-out of receiving marketing-related messages, we may still send you important administrative messages.</p>
                    <p className="text-gray-700 my-10 italic">Rights of California Consumers</p>
                    <p className="text-gray-700 my-10">We provide California consumers with specific rights regarding their Personal Information as required by California law.  This section describes the rights that you have under California law and explains how to exercise those rights.</p>
                    <p className="text-gray-700 my-10 italic">Right to Know About Personal Information Collected, Used, or Disclosed</p>
                    <p className="text-gray-700 my-10">You have the right to request that we disclose certain information to you about our collection, use and disclosure of your Personal Information. Once we receive your request and verify your identity, we will disclose to you:  (a) the categories of Personal Information we collected about you; (b) the categories of sources for the Personal Information; (c) our business or commercial purpose for collecting or disclosing that Personal Information; (d) the categories of third parties with whom we shared that Personal Information; and (e) the specific pieces of Personal Information we collected about you in the preceding 12 months.</p>
                    <p className="text-gray-700 my-10 italic">Right to Delete Personal Information Collected or Maintained</p>
                    <p className="text-gray-700 my-10">You have the right to request that we delete any of your Personal Information that we collected from you and retained, subject to certain exceptions. Once we receive and confirm your request and verify your identity, we will permanently delete (and direct our service providers to delete) your Personal Information from our records, unless a legal exception to deletion applies.</p>
                    <p className="text-gray-700 my-10 italic">Right to Correction of Personal Information</p>
                    <p className="text-gray-700 my-10">You have the right to request that we correct inaccurate Personal Information that we maintain about you.</p>
                    <p className="text-gray-700 my-10">We have no actual knowledge of selling or sharing Personal Information of minors under 16 years of age to non-affiliated third parties without affirmative consent.</p>
                    <p className="text-gray-700 my-10">Additionally, if you enable a browser-based opt-out preference signal, such as Global Privacy Control, in certain jurisdictions, upon receipt or detection, we will make reasonable efforts to treat the signal as a valid request to opt out of the sharing of Personal Information for targeted advertising pursuant to applicable law. </p>
                    <p className="text-gray-700 my-10 italic">Right to Opt-Out of the Sharing of Your Personal Information for Targeted Advertising</p>
                    <p className="text-gray-700 my-10">You have the right to opt out of the sharing of your Personal Information for targeted advertising. As described above, we engage in personalized or targeted advertising through the use of third-party cookies or pixels. In connection with such advertising, we may share or sell the following categories of Personal Information with marketing partners: (a) device information and identifiers, such as unique advertising identifiers and cookies, and IP address; (b) usage information, such as browsing history; (c) location information, such as city; and (d) inference data. We do not otherwise sell your Personal Information, and we have no actual knowledge of selling or sharing Personal Information of minors under 16 years of age to non-affiliated third parties without affirmative consent.</p>
                    <p className="text-gray-700 my-10">Additionally, if you enable a browser-based opt-out preference signal, such as Global Privacy Control, in certain jurisdictions, upon receipt or detection, we will make reasonable efforts to treat the signal as a valid request to opt out of the sharing of Personal Information for targeted advertising pursuant to applicable law.</p>
                    <p className="text-gray-700 my-10 italic">Exercising Your Rights</p>
                    <p className="text-gray-700 my-10">To exercise the rights described above, please submit a verifiable consumer request to us by either:</p>
                    <ul className="ml-10 list-disc text-gray-700 my-10">
                        <li>
                            <p className="text-gray-700 my-10">writing us at: Ovation Tax Group, 19839 Nordhoff St, Northridge, CA 91324-3331</p>
                        </li>
                        <li>
                            <p className="text-gray-700 my-10">visiting https://docs.ovationtaxgroup.com/CCPARequest.aspx</p>
                        </li>
                    </ul>
                    <p className="text-gray-700 my-10">Your exercise of these rights will have no adverse effect on the price and quality of our goods or services.</p>
                    <p className="text-gray-700 my-10 italic">Authorizing an Agent</p>
                    <p className="text-gray-700 my-10">You may designate an authorized agent to submit your consumer request on your behalf, so long as the authorized agent has your written permission to do so and you have taken steps to verify your identity directly with us.</p>
                    <p className="text-gray-700 my-10 italic">Verifying Your Request</p>
                    <p className="text-gray-700 my-10">We cannot respond to your request or provide you with Personal Information if we cannot verify your identity or authority to make the request and confirm the Personal Information relates to you.  To verify your identity, we ask that you provide us with the following information when you submit your request: (a) full name and (b) phone number or email.  We will use the above information to verify your identity.  Depending on your type of request or the information requested by you, we may require additional information to verify your identity and fulfill your request.</p>
                    <p className="text-gray-700 my-10 underline">Personal Information Concerning Children</p>
                    <p className="text-gray-700 my-10">Our Sites and SMS Services are not intended for anyone under the age of 16, and we do not knowingly collect Personal Information from individuals under the age of 16.</p>
                    <p className="text-gray-700 my-10 underline">Updates to the Policy</p>
                    <p className="text-gray-700 my-10">We may update this Policy periodically as we offer new products and services, and as our business, technology, and laws change. You can determine when this Policy was last revised by referring to the “Last updated” reference below.  Any changes will become effective upon posting of the revised Policy. If we make material changes to the policy, we will provide notice to you of the changes as required by applicable law.</p>


                    <p className="text-gray-700 my-10">Contact Us</p>

                    <p className="text-gray-900 text-lg my-2">Ovation Tax Group</p>
                    <p className="text-gray-900 my-2">Attn: Privacy Compliance, 19839 Nordhoff St, Northridge, CA CA 91324-3331
                    </p>
                    <p className="text-gray-900 my-2">Email: info@ovationtaxgroup.com</p>
                </div>
            </div>
        </div>
    );
}
